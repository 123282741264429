import axios from 'axios';
import { API_URL } from './constants';

export const fetch = async (finalUrl, token) => {
  try {
    const headers = { authorization: `Bearer ${token}` };
    console.log(`\n\n${API_URL}${finalUrl}`);
    return await axios.get(`${API_URL}${finalUrl}`, { headers });
  } catch (err) {
    console.log(`ERROR ON FETCH: ${err}`);
    return { data: null, error: err };
  }
};

export const merchantFetch = async (finalUrl, apiKey) => {
  try {
    const headers = { 'x-api-key': apiKey };
    console.log(`\n\n${API_URL}${finalUrl}`);
    return await axios.get(`${API_URL}${finalUrl}`, { headers });
  } catch (err) {
    console.log(`ERROR ON FETCH: ${err}`);
    return { data: null, error: err };
  }
};

export const post = async (finalUrl, body, token) => {
  try {
    const headers = { authorization: `Bearer ${token}` };
    console.log(`\n\n${API_URL}${finalUrl}`, body);
    return await axios.post(`${API_URL}${finalUrl}`, body, { headers });
  } catch (err) {
    console.log(`ERROR ON POST: ${err}`);
    return { data: null, error: err };
  }
};

export const merchantPost = async (finalUrl, body, apiKey) => {
  try {
    const headers = { 'x-api-key': apiKey };
    console.log(`\n\n${API_URL}${finalUrl}`, body);
    return await axios.post(`${API_URL}${finalUrl}`, body, { headers });
  } catch (err) {
    console.log(`ERROR ON POST: ${err}`);
    return { data: null, error: err };
  }
};

export const patch = async (finalUrl, body, token) => {
  try {
    const headers = { authorization: `Bearer ${token}` };
    console.log(`\n\n${API_URL}${finalUrl}`, body);
    return await axios.patch(`${API_URL}${finalUrl}`, body, { headers });
  } catch (err) {
    console.log(`ERROR ON PATCH: ${err}`);
    return { data: null, error: err };
  }
};
