import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/AuthProvider';

function PrivateRoute({ redirect = '/login' }) {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    const redirectUrl = `${redirect}${location.search}`;
    return <Navigate to={redirectUrl} />;
  }

  return <Outlet />;
}

export default PrivateRoute;
