const PROD_URL = 'https://api.webauthntesis.work';
const DEV_URL = 'http://localhost:3004';

const PROD_DOMAIN_URL = 'https://webauthntesis.work';
const DEV_DOMAIN_URL = 'http://localhost:3000';

const API_URL = process.env.REACT_APP_ENVIRONMENT === 'DEV' ? DEV_URL : PROD_URL;
const API_DOMAIN_URL = process.env.REACT_APP_ENVIRONMENT === 'DEV' ? DEV_DOMAIN_URL : PROD_DOMAIN_URL;

const ASSETS_URL = 'https://webauthntesis.work/assets';

module.exports = {
  API_URL,
  ASSETS_URL,
  API_DOMAIN_URL,
};
