import React, { useContext, createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../utils/apiConnection';
import { authenticate } from '../utils/webauthn';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState(
    localStorage.getItem('user') || null,
  );
  const [token, setToken] = useState(
    localStorage.getItem('token') || null,
  );
  const [defaultEmail, setDefaultEmail] = useState(
    localStorage.getItem('defaultEmail') || null,
  );
  const [pendingPayment, setPendingPayment] = useState(false);

  const navigate = useNavigate();

  const logOut = () => {
    setUser(null);
    localStorage.removeItem('user');
    navigate('/login');
  };

  const logIn = (
    { access_token: accessToken },
    email,
    deviceToken,
    redirect = '/',
  ) => {
    setUser(email);
    localStorage.setItem('user', email);
    setDefaultEmail(email);
    localStorage.setItem('defaultEmail', email);
    setToken(accessToken);
    localStorage.setItem('token', accessToken);
    localStorage.setItem('fcmToken', deviceToken);
    localStorage.setItem('deviceToken', deviceToken);
    navigate(redirect);
  };

  const register = (email) => {
    setDefaultEmail(email);
    localStorage.setItem('defaultEmail', email);
    navigate('/verify', { state: { email } });
  };

  const recover = (email) => {
    setDefaultEmail(email);
    localStorage.setItem('defaultEmail', email);
    post('/auth/new-otp', { email }, token);
    navigate('/verify', { state: { email, redirect: '/recover' } });
  };

  const authenticateForAction = async () => {
    try {
      const { error } = await authenticate(defaultEmail);
      return !error;
    } catch (err) {
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        defaultEmail,
        register,
        logIn,
        logOut,
        token,
        recover,
        authenticateForAction,
        pendingPayment,
        setPendingPayment,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
