import React from 'react';
import styles from './Hello.module.css';

function Hello({ name }) {
  return (
    <div className={styles.container}>
      <span className={styles.name}>¡{`Hola ${name}`.trim()}!</span>
      <p className={styles.subtitle}>Que bueno verte de nuevo</p>
    </div>
  );
}

export default Hello;
