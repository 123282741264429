import React from 'react';
import { ASSETS_URL } from '../../utils/constants';

function LoadingSpinner() {
  return (
    <div className={'loading-spinner'} style={{ marginTop: '30px' }}>
      <img src={`${ASSETS_URL}/loading.gif`} alt="loading" />
    </div>
  );
}

export default LoadingSpinner;
