import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import styles from './Authenticator.module.css';
import { formatDate } from '../../../utils/dates';
import { ASSETS_URL } from '../../../utils/constants';

const passkey = `${ASSETS_URL}/webauthn/passkey.png`;

function Authenticator({
  lastUsed, timesUsed, created, name, deleteAuth,
}) {
  return (
    <div className={styles.authenticator}>
        <div className={styles.header}>
          <img src={passkey} alt="passkey"></img>
          <span className={styles.name}>{name}</span>
        </div>
        <div className={styles.body}>
          <div className={styles.content}>
            <p><span className={styles.bold}>Fecha de creación: </span> {formatDate(created)}</p>
            <p><span className={styles.bold}>Último uso: </span>{formatDate(lastUsed)}</p>
            <p><span className={styles.bold}>Usos: </span>{timesUsed}</p>
          </div>
          <div
            className={styles.delete}
            onClick={() => deleteAuth(created)}
          >
            <FaTrashAlt />
          </div>
        </div>
    </div>
  );
}

export default Authenticator;
