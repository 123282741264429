import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const VAPID_KEY = 'BFQ7M6aj3zyMbW-dn5U5qOKWH1P7lNFLT6KDKnyGmyYAqWf3purkMzWAN48dgESFGAKc_FX_CsvXT7NXmuJ9oe4';

const firebaseConfig = {
  apiKey: 'AIzaSyDUzNl43gym8Y02dUvxZNzdlOHpHMcfc88',
  authDomain: 'push-webauthn.firebaseapp.com',
  projectId: 'push-webauthn',
  storageBucket: 'push-webauthn.appspot.com',
  messagingSenderId: '678041604779',
  appId: '1:678041604779:web:50fcd851d62e3f3a1a1dee',
  measurementId: 'G-5XW6LP7KXK',
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestToken = async () => {
  try {
    const currentToken = await getToken(
      messaging,
      { vapidKey: VAPID_KEY, serviceWorkerRegistration: await navigator.serviceWorker.ready },
    );
    if (currentToken) {
      console.log('ya tieneeeee', currentToken);
      return currentToken;
    }
    console.log('no tieneeee, nullll');
    return null;
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
    return null;
  }
};

export const onMessageListener = () => new Promise((resolve) => {
  onMessage(messaging, (payload) => {
    resolve(payload);
  });
});
