import React from 'react';

import BottomMenu from '../../components/BottomMenu/BottomMenu';
import QRScanner from '../../components/QrScanner/QrScanner';

function Scan() {
  return (
    <div className={'page'}>
      <div>
        <div>
          <QRScanner />
        </div>
      </div>
      <BottomMenu />
    </div>
  );
}

export default Scan;
