import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function PWARoute({ redirect = '/download' }) {
  if (process.env.NODE_ENV === 'development') return <Outlet />;
  const isInStandaloneMode = () => (window.matchMedia('(display-mode: standalone)').matches)
    || (window.navigator.standalone)
    || document.referrer.includes('android-app://');

  if (!isInStandaloneMode()) {
    return <Navigate to={redirect} />;
  }

  return <Outlet />;
}

export default PWARoute;
