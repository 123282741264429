import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import Verify from './pages/Verify/Verify';
import Home from './pages/Home/Home';
import Security from './pages/Security/Security';
import Cards from './pages/Cards/Cards';
import Activity from './pages/Activity/Activity';
import DeleteCard from './pages/DeleteCard/DeleteCard';
import Payment from './pages/Payment/Payment';
import Recover from './pages/Recover/Recover';
import Identify from './pages/Identify/Identify';
import FlowFinish from './pages/FlowFinish/FlowFinish';
import Scan from './pages/Scan/Scan';
import Generate from './pages/Generate/Generate';

import PrivateRoute from './router/PrivateRoute';

import AuthProvider from './hooks/AuthProvider';
import PWARoute from './router/PWARoute';
import Download from './pages/Download/Download';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route element={<PWARoute />}>
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Home />} />
            </Route>
          </Route>

          <Route element={<PWARoute />}>
            <Route element={<PrivateRoute />}>
              <Route path="/security" element={<Security />} />
            </Route>
          </Route>

          <Route element={<PWARoute />}>
            <Route element={<PrivateRoute />}>
              <Route path="/cards" element={<Cards />} />
            </Route>
          </Route>

          <Route element={<PWARoute />}>
            <Route element={<PrivateRoute />}>
              <Route path="/delete-card" element={<DeleteCard />} />
            </Route>
          </Route>

          <Route element={<PWARoute />}>
            <Route element={<PrivateRoute redirect="/identify" />}>
              <Route path="/payment" element={<Payment />} />
            </Route>
          </Route>

          <Route element={<PWARoute />}>
            <Route element={<PrivateRoute />}>
              <Route path="/flow-finish" element={<FlowFinish />} />
            </Route>
          </Route>

          <Route element={<PWARoute />}>
            <Route element={<PrivateRoute />}>
              <Route path="/scan" element={<Scan />} />
            </Route>
          </Route>

          <Route element={<PWARoute />}>
            <Route element={<PrivateRoute />}>
              <Route path="/activity" element={<Activity />} />
            </Route>
          </Route>

          <Route element={<PWARoute />}>
            <Route path="/verify" element={<Verify />} />
          </Route>

          <Route element={<PWARoute />}>
            <Route path="/recover" element={<Recover />} />
          </Route>

          <Route element={<PWARoute />}>
            <Route path="/login" element={<Login />} />
          </Route>

          <Route element={<PWARoute />}>
            <Route path="/register" element={<Signup />} />
          </Route>

          <Route element={<PWARoute />}>
            <Route path="/identify" element={<Identify />} />
          </Route>

          <Route path="/download" element={<Download />} />

          <Route path="/generate" element={<Generate />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
