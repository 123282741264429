import React from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import styles from './ConfirmModal.module.css';
import Button from '../../Button/Button';

function ConfirmModal({
  title,
  description,
  onConfirm,
  onAbort,
  confirmText,
  abortText,
  disabled,
}) {
  return (
    <div className={styles.mask}>
        <div className={styles.container}>
            <div className={styles['container-content']}>
                <div className={styles.header}>
                    <div />
                    <h2>{title}</h2>
                    <div
                        onClick={onAbort}
                        className={styles['close-button']}
                    >
                        <IoCloseSharp />
                    </div>
                </div>
                <div className={styles.divider} />
                <div className={styles.body}>
                    <p>{description}</p>
                    <Button
                        color="var(--orange)"
                        text={confirmText}
                        onClick={onConfirm}
                        extraStyles={{ color: 'white' }}
                        loading={disabled}
                    />
                    <Button
                        color="white"
                        text={abortText}
                        onClick={onAbort}
                        extraStyles={{ color: 'var(--orange)', border: '2px solid var(--orange)' }}
                    />
                </div>
            </div>
        </div>
    </div>
  );
}

export default ConfirmModal;
