import React from 'react';
import styles from './PasskeyErrorBanner.module.css';

import { ASSETS_URL } from '../../utils/constants';

const passkeyWentWrongImg = `${ASSETS_URL}/webauthn/passkey-went-wrong.png`;

function PasskeyErrorBanner({ extraStyles = {} }) {
  return (
    <section className={styles['create-passkey']} style={extraStyles} >
        <img
            src={passkeyWentWrongImg}
            alt="passkey-went-wrong"
            className={styles['create-passkey-img']}
        />
        <div className={styles['passkey-not-created']}>
            <h3>Ups! Algo salió mal</h3>
            <p className={styles.a}>
                La clave de acceso no se creó correctamente.
                Puedes intentarlo de nuevo o registrarte con una contraseña.
            </p>
        </div>
    </section>
  );
}

export default PasskeyErrorBanner;
