/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import styles from './Generate.module.css';
import { merchantPost, merchantFetch } from '../../utils/apiConnection';

import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import Input from '../../components/Input/Input';
import Ilustration from '../../components/Ilustration/Ilustration';
import LoadingSpinner from '../../components/LoadingSpinner';

// THESE ARE ALL FOR DEMO PURPOSES
const merchants = [
  {
    merchant: 'Zara',
    apiKey: '7d5b95a613b96a09df01cd94650fdecdfc829e87dae29641612cca4ed0a7b39a',
  },
  {
    merchant: 'Recarga STM',
    apiKey: '7d5b95a613b96a09df01cd94650fdecdfc829e87dae29641612cca4ed0a7b39b',
  },
  {
    merchant: 'Frog',
    apiKey: '7d5b95a613b96a09df01cd94650fdecdfc829e87dae29641612cca4ed0a7b39d',
  },
  {
    merchant: 'Life Cinemas',
    apiKey: '7d5b95a613b96a09df01cd94650fdecdfc829e87dae29641612cca4ed0a7b39c',
  },
];

function Signup() {
  const [apiKey, setApiKey] = useState(merchants[0].apiKey);
  const [userEmail, setUserEmail] = useState('');
  const [userEmailError, setUserEmailError] = useState('');
  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [paymentData, setPaymentData] = useState('');
  const [paymentError, setPaymentError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingPush, setLoadingPush] = useState(false);
  const initMsg = 'Solicitud de pago enviada al cliente';
  const [msg, setMsg] = useState(initMsg);
  const [approved, setApproved] = useState(true);

  const clearData = () => {
    setPaymentData(null);
    setUserEmail('');
    setAmount(0);
    setMsg(initMsg);
    setApproved(true);
  };

  const clearErrors = () => {
    setAmountError('');
    setUserEmailError('');
    setPaymentError('');
  };

  const generateQR = async () => {
    const body = {
      userEmail,
      amount: Number(amount),
      info: {
        push: true,
        qr: true,
      },
    };
    const { data, error } = await merchantPost('/payments/generate', body, apiKey);
    if (data) {
      console.log(data);
      setPaymentData(data);
    } else if (error) {
      if (error.message.includes('401')) {
        setPaymentError('Api Key no registrada');
      } else if (error.message.includes('404')) {
        setPaymentError('Cliente no encontrado');
      } else {
        setPaymentError('Algo salio mal, verifique los datos o comuniquese con soporte');
      }
    }
    setLoading(false);
  };

  const validatePaymentGeneration = async () => {
    setDisabled(true);
    clearErrors();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userEmail)) {
      setUserEmailError('Email de cliente no valido');
      setDisabled(false);
      return;
    }
    if (!amount || amount <= 0 || typeof Number(amount) !== 'number') {
      setAmountError('Valor no valido');
      setDisabled(false);
      return;
    }
    setDisabled(false);
    setLoading(true);
    setTimeout(generateQR, 1000);
  };

  const notifyClient = async () => {
    setLoadingPush(true);
    const id = paymentData.payment._id;
    const qparams = `id=${id}&push=true&qr=false`;
    const baseUrl = '/payments/data';
    const { data, error } = await merchantFetch(`${baseUrl}?${qparams}`, apiKey);
    if (data) {
      setMsg('Notificacion enviada al cliente');
      setApproved(true);
    } else if (error) {
      setMsg('Error al enviar la notificacion');
      setApproved(false);
    }
    setLoadingPush(false);
  };

  return (
    <div className={'page'}>
      <Header />
      <div className={styles['page-content']}>
        {loading && <LoadingSpinner />}
        <div className={styles.options}>
          {!loading && !paymentData
            && <>
            <select
              id="merchants"
              name="merchants"
              onChange={(e) => setApiKey(e.target.value)}
            >
              {merchants.map((merchant, idx) => (
                <option key={idx} value={merchant.apiKey}>
                  {merchant.merchant}
                </option>
              ))}
            </select>
            <Input
              type="text"
              placeholder="Email del cliente"
              value={userEmail}
              changeValue={(value) => setUserEmail(value.toLowerCase())}
              label="Email del cliente"
              errorMsg={userEmailError}
            />
            <Input
              type="number"
              placeholder="Valor"
              value={amount}
              changeValue={(value) => setAmount(value)}
              label="Valor"
              errorMsg={amountError}
              min="0"
            />
            <Button
              color="var(--orange)"
              text={'Crear Pago'}
              onClick={validatePaymentGeneration}
              extraStyles={{ color: 'white' }}
              disabled={disabled}
            />
            {paymentError && <span className={styles.error}>{paymentError}</span>}
            </>
          }
          {!loading && paymentData
            && (<>
              <img src={paymentData.qr} className={styles.qr} />
              <div className={styles.buttons} >
                <Button
                  color="var(--orange)"
                  text={'Notificar de nuevo'}
                  onClick={notifyClient}
                  extraStyles={{ color: 'white', textAlign: 'center' }}
                  disabled={loadingPush}
                />
                <Button
                  color="white"
                  text={'Nuevo pago'}
                  onClick={clearData}
                  extraStyles={{ color: 'var(--orange)', border: '1px solid var(--orange)' }}
                />
              </div>
              {
                msg && (
                  <div className={styles.header}>
                    <span className={styles.title}>{msg}</span>
                    <Ilustration
                      ilustration={approved ? 'approved' : 'unapproved'}
                      extraStyles={{ width: '25px', height: '25px', margin: '0' }}
                    />
                  </div>
                )
              }
            </>)
          }
        </div>
      </div>
    </div>
  );
}

export default Signup;
