import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './Login.module.css';
import Button from '../../components/Button/Button';
import { authenticate } from '../../utils/webauthn';
import { useAuth } from '../../hooks/AuthProvider';
import { requestToken } from '../../utils/pushNotifications';
import Header from '../../components/Header/Header';
import Ilustration from '../../components/Ilustration/Ilustration';
import BannerModal from '../../components/Modals/BannerModal/BannerModal';
import PasskeyLoginErrorBanner from '../../components/PasskeyLoginErrorBanner/PasskeyLoginErrorBanner';

function Login() {
  const {
    logIn, defaultEmail, register, recover,
  } = useAuth();
  const [email, setEmail] = React.useState(defaultEmail);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [retryMessage, setRetryMessage] = useState('');
  const [retryAction, setRetryAction] = useState(null);
  const [errorDescription, setErrorDescription] = useState('');

  const validateAndLogin = async () => {
    setLoading(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorDescription('El email no es valido');
      setRetryMessage(null);
      setRetryAction(null);
      setShowErrorModal(true);
    } else if (email) {
      const deviceToken = await requestToken();
      const response = await authenticate(email, deviceToken);
      if (response.error) {
        console.log(response);
        if (response.error === 'User not found') {
          setErrorDescription('El email no esta registrado en la aplicacion');
          setRetryMessage(null);
          setRetryAction(null);
        } else if (response.error === 'User is not verified yet') {
          setRetryMessage('Verificar casilla');
          setErrorDescription('No puedes entrar en la aplicacion sin haber verificado la casilla previamente');
          setRetryAction(() => () => register(email));
        } else if (response.error === 'No authenticators found') {
          setRetryMessage('Recuperar cuenta');
          setErrorDescription('El email no tiene una clave de acceso asociada');
          setRetryAction(() => () => recover(email));
        } else {
          setRetryMessage('Recuperar cuenta');
          setRetryAction(() => () => recover(email));
          setErrorDescription('No tienes acceso a ninguna de tus claves? Recupera tu cuenta aquí');
        }
        setShowErrorModal(true);
      }
      if (response.message) logIn(response, email, deviceToken);
    } else {
      setShowErrorModal(true);
    }
    setLoading(false);
  };

  return (
    <div className="page">
      <div className={styles['page-content']}>
        <Header />
        <div />
        <Ilustration
          ilustration="wallet"
          extraStyles={{ width: '150px', height: '150px' }}
        />
        <div className={styles.options}>
          <input
            className={styles.input}
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
          <Button
            color="var(--orange)"
            text="Iniciar sesión"
            onClick={validateAndLogin}
            extraStyles={{ color: 'white' }}
            loading={loading}
          />
          <div className={styles['not-registered']}>
            <Link
              to="/register"
              style={{ color: 'black' }}
            >
              Aun no estas registrado? Registrate aquí
            </Link>
          </div>
        </div>
      </div>
      {showErrorModal
        && <BannerModal
          closeModal={() => setShowErrorModal(false)}
        >
          <PasskeyLoginErrorBanner
            retryAction={retryAction}
            retryMessage={retryMessage}
            errorDescription={errorDescription}
            extraStyles={{ background: 'white' }}
          />
        </BannerModal>
      }
    </div>
  );
}

export default Login;
