import React from 'react';
import styles from './PasskeyCreatedBanner.module.css';
import { ASSETS_URL } from '../../utils/constants';

const passkeyCreated = `${ASSETS_URL}/webauthn/passkey-created.png`;

function PasskeyCreatedBanner({ scrollToPasskey }) {
  return (
        <section className={styles['passkey-created']}>
            <img
                src={passkeyCreated}
                alt="passkey-created"
                className={styles['passkey-created-img']}
            />
            <h3>Clave de acceso creada</h3>
            <p
                className={styles['view-passkey']}
                onClick={scrollToPasskey}
            >
                Ver clave de acceso
            </p>
        </section>
  );
}

export default PasskeyCreatedBanner;
