import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './DeleteCard.module.css';

import Button from '../../components/Button/Button';
import BottomMenu from '../../components/BottomMenu/BottomMenu';
import Header from '../../components/Header/Header';
import Card from '../../components/Card/Card';

import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import { patch } from '../../utils/apiConnection';
import { useAuth } from '../../hooks/AuthProvider';

function DeleteCard() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    franchise, lastDigits, index, type, id,
  } = location.state;
  const { token } = useAuth();
  const [showDeleteCard, setShowDeleteCard] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const deleteCard = async () => {
    setDisabled(true);
    setTimeout(async () => {
      const { error } = await patch(`/cards/${id}`, {}, token);
      if (error) {
        navigate('/flow-finish', {
          state: {
            status: 'unapproved',
            back: 'cards-back',
            message: 'La tarjeta no pudo ser eliminada, intente luego.',
          },
        });
      } else {
        navigate('/flow-finish', {
          state: {
            status: 'approved',
            back: 'cards-back',
            message: 'Tarjeta eliminada correctamente',
          },
        });
      }
      setDisabled(false);
    }, 2000);
  };

  return (
    <div className={'page'}>
      <Header
        title='Eliminar tarjeta'
        leftIcons={['delete-cards-back']}
      />
      <div className={styles['page-content']}>
        <div className={styles['cards-container']}>
          <Card
            className={styles.card}
            franchise={franchise}
            lastDigits={lastDigits}
            index={index}
            type={type}
            showMore={false}
            onClickMore={false}
            extraStyles={{ height: '200px' }}
            id={id}
          />
        </div>
        <Button
          color="var(--orange)"
          text="Eliminar esta tarjeta"
          onClick={() => setShowDeleteCard(true)}
          disabled={disabled}
          extraStyles={{ color: 'white' }}
        />
      </div>
      <BottomMenu />
      {showDeleteCard
        && <ConfirmModal
          onConfirm={deleteCard}
          disabled={disabled}
          confirmText={'Eliminar'}
          abortText={'Cancelar'}
          onAbort={() => setShowDeleteCard(false)}
          title={'Eliminar esta tarjeta'}
          description={`
            ¿Estás seguro de que deseas eliminar esta tarjeta?
            De hacerlo podrás agregarla nuevamente en cualquier momento.
          `}
        />
      }
    </div>
  );
}

export default DeleteCard;
