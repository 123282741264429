import React from 'react';
import QrScanner from 'qr-scanner';
import { useNavigate } from 'react-router-dom';
import styles from './QrScanner.module.css';
import { useAuth } from '../../hooks/AuthProvider';

function QRScanner() {
  const scanner = React.useRef(null);
  const videoEl = React.useRef(null);
  const qrBoxEl = React.useRef(null);
  const [msg, setMsgReal] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const { defaultEmail: email } = useAuth();
  const [shake, setShake] = React.useState(false);
  const navigate = useNavigate();

  const setMsg = (mesg) => {
    setTimeout(() => {
      setMsgReal(mesg);
    }, 250);
  };

  const isQRValid = (data) => {
    try {
      console.log(data);
      if (
        !data.includes('localhost:3000/payment?payload=')
        && !data.includes('webauthntesis.work/payment?payload=')
      ) {
        return false;
      }
      const payload = JSON.parse(atob(data.split('payload=')[1]));
      console.log(payload);
      if (payload.payer.email !== email) return false;
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const triggerShake = () => {
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, 1000);
  };

  React.useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(
        videoEl?.current,
        (result) => {
          console.log('here');
          if (isQRValid(result.data)) {
            setMsg('Redirigiendo...');
            setTimeout(() => {
              window.location.href = result.data;
            }, 1000);
          } else {
            setMsg('Código QR inválido');
          }
        },
        {
          onDecodeError: () => {},
          highlightCodeOutline: true,
          overlay: qrBoxEl?.current || undefined,
        },
      );
      scanner?.current.start()
        .then(() => {
          setMsg('Escanea el código QR');
        })
        .catch(() => {
          setErrorMsg('Haz click aquí o reinicia la aplicación para pedir permisos de cámara');
        });
    }
    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  return (
    <div className={styles['qr-reader']}>
      <video className={styles.video} ref={videoEl}></video>
      {msg && <div
        className={`${styles['qr-box']} ${shake ? styles.shake : ''}`}
      >
        {msg}
      </div>}
      {errorMsg && <div
        onClick={() => navigate(0)}
        className={`${styles['qr-box-error']}`}
      >
        {errorMsg}
      </div>}
    </div>
  );
}

export default QRScanner;
