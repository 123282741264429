import React, { useState } from 'react';
import styles from './CreatePasskeyBanner.module.css';

import Button from '../Button/Button';
import { ASSETS_URL } from '../../utils/constants';

const createPasskeyImg = `${ASSETS_URL}/webauthn/create-passkey.png`;

function CreatePasskeyBanner({ createPasskey, name, extraStyles = {} }) {
  const [passkeyName, setPasskeyName] = useState(name ? `${name}'s passkey` : '');
  return (
    <section className={styles['create-passkey']} style={extraStyles} >
        <img
            src={createPasskeyImg}
            alt="create-passkey"
            className={styles['create-passkey-img']}
        />
        <div className={styles['passkey-not-created']}>
          <div className={styles.info}>
            <h3>Con las claves de acceso, no necesitas recordar contraseñas complejas</h3>
            <p className={styles.q}>• ¿Qué son las claves de acceso?</p>
            <p className={styles.a}>
              Las claves de acceso son llaves digitales
              encriptadas que creas usando tu huella dactilar,
              rostro o bloqueo de pantalla.
            </p>
            <p className={styles.q}>• ¿Dónde se guardan las claves de acceso?</p>
            <p className={styles.a}>
              Las claves de acceso se guardan en tu gestor de contraseñas,
              así puedes iniciar sesión en otros dispositivos.
            </p>
          </div>
          {createPasskey && <input
            className={styles.input}
            type="text"
            placeholder='Coloca un nombre a tu clave de acceso'
            value={passkeyName}
            onChange={(e) => setPasskeyName(e.target.value)}
          />}
          {createPasskey && <Button
              color="var(--orange)"
              text="Crear clave de acceso"
              onClick={() => createPasskey(passkeyName)}
              extraStyles={{ color: 'white' }}
              disabled={!passkeyName}
          />}
        </div>
    </section>
  );
}

export default CreatePasskeyBanner;
