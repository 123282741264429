import {
  startRegistration,
  startAuthentication,
} from '@simplewebauthn/browser';

import { fetch, post } from './apiConnection';

const register = async ({
  name,
  lastname,
  email,
  phone,
  passkeyName,
}) => {
  try {
    const url = `/auth/registration-options?username=${name}&email=${email}`;
    const { data: options } = await fetch(url);
    console.log('get register options - step 1:', options);
    if (options.error) {
      console.log('error:', options.error);
      return { message: null, error: options.error };
    }
    const attResp = await startRegistration(options);
    console.log('register user - step 2:', attResp);
    const { data: response } = await post('/auth/register', {
      ...attResp,
      username: name,
      lastname,
      email,
      phone,
      challenge: options.challenge,
      passkeyName,
    });
    console.log('verify user reigstered correctly - step 3:', response);
    console.log('response.verified:', response.verified);
    if (response.verified) {
      return { message: 'Registered', error: null };
    }
    return {
      message: null,
      error: 'Error creating credentials - not verified',
    };
  } catch (err) {
    console.error(err);
    return { message: null, error: `Error creating credentials ${err}` };
  }
};

const authenticate = async (email, deviceToken) => {
  try {
    const url = `/auth/authentication-options?email=${email}`;
    const { data: options } = await fetch(url);
    console.log('get authentication options - step 1:', options);
    if (options.error) {
      return { message: null, error: options.error };
    }
    const authResp = await startAuthentication(options);
    console.log('authenticate user - step 2:', authResp);
    const { data: response } = await post('/auth/verify-login', {
      ...authResp,
      email,
      deviceToken,
    });
    console.log('verify user authenticated correctly - step 3:', response);
    console.log('response.verified:', response.verified);
    if (response.verified) {
      return { message: 'Registered', error: null, ...response };
    }
    return { message: null, error: 'Error authenticating' };
  } catch (err) {
    console.error(err);
    return { message: null, error: 'Error authenticating' };
  }
};

const addPasskey = async (email, passkeyName) => {
  try {
    const url = `/auth/new-passkey-options?email=${email}`;
    const { data: options } = await fetch(url);
    console.log('get register options - step 1:', options);
    if (options.error) {
      console.log('error:', options.error);
      return { message: null, error: options.error };
    }
    const attResp = await startRegistration(options);
    console.log('register user - step 2:', attResp);
    const { data: response } = await post('/auth/add-passkey', {
      ...attResp,
      email,
      challenge: options.challenge,
      passkeyName,
    });
    console.log('verify user reigstered correctly - step 3:', response);
    console.log('response.verified:', response.verified);
    if (response.verified) {
      return { message: 'Registered', error: null };
    }
    return {
      message: null,
      error: 'Error creating credentials - not verified',
    };
  } catch (err) {
    console.error(err);
    return { message: null, error: `Error creating credentials ${err}` };
  }
};

export { register, authenticate, addPasskey };
