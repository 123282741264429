import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import styles from './Carousel.module.css';

function Carousel({ children, activeCardIndex, setActiveCardIndex }) {
  const activeIndex = activeCardIndex;
  const goForward = () => setActiveCardIndex((activeIndex + 1) % children.length);
  const goBack = () => {
    if (activeIndex === 0) setActiveCardIndex(children.length - 1);
    else setActiveCardIndex((activeIndex - 1) % children.length);
  };

  return (
    <>
      <div className={styles.carousel}>
        <div className={styles['carousel-inner']}>
            {children[activeIndex]}
        </div>
      </div>
      {children.length > 1
        && <div className={styles['carousel-dots']}>
          <div
            className={styles['carousel-arrow']}
            onClick={goBack}
          >
            <IoIosArrowBack />
          </div>
          {children.map((_, index) => (
            <div
              key={index}
              className={`
                ${styles['carousel-dot']}
                ${index === activeIndex ? styles['carousel-dot-active'] : ''}
              `}
            />
          ))}
          <div
            className={styles['carousel-arrow']}
            onClick={goForward}
          >
            <IoIosArrowForward />
          </div>
        </div>
      }
    </>
  );
}

export default Carousel;
