/* eslint-disable */

import React, { useEffect, useState } from 'react';
import styles from './Download.module.css';
import Header from '../../components/Header/Header';
import Ilustration from '../../components/Ilustration/Ilustration';

function Download() {
  const [browser, setBrowser] = useState('');
  const [os, setOs] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // Detectar el navegador
    if (/Chrome/.test(userAgent) && !/Edge|OPR/.test(userAgent)) {
      setBrowser('Chrome');
    } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
      setBrowser('Safari');
    } else {
      setBrowser('Otro navegador');
    }

    // Detectar el sistema operativo
    if (/Mac OS X|iPhone|iPad/.test(userAgent)) {
      setOs('iOS');
    } else if (/Android/.test(userAgent)) {
      setOs('Android');
    } else {
      setOs('Otro sistema operativo');
    }
  }, []);

  const renderInstructions = () => {
    if (os === 'iOS' && browser === 'Safari') {
      return (
        <>
          <div className={styles.elem}>
            <div className={styles.dot} />
            <p>Entra desde tu navegador Safari</p>
          </div>
          <div className={styles.elem}>
            <div className={styles.dot} />
            <p>En la barra inferior, presiona el botón central</p>
          </div>
          <div className={styles.elem}>
            <div className={styles.dot} />
            <p>En las opciones, presiona "Agregar a inicio"</p>
          </div>
        </>
      );
    } else if (os === 'Android' && browser === 'Chrome') {
      return (
        <>
          <div className={styles.elem}>
            <div className={styles.dot} />
            <p>Entra desde tu navegador Chrome</p>
          </div>
          <div className={styles.elem}>
            <div className={styles.dot} />
            <p>Presiona el botón de menú (tres puntos en la esquina superior derecha)</p>
          </div>
          <div className={styles.elem}>
            <div className={styles.dot} />
            <p>Selecciona "Añadir a pantalla de inicio"</p>
          </div>
        </>
      );
    }else if (os === 'iOS' && browser === 'Chrome') {
      return (
        <>
          <div className={styles.elem}>
            <div className={styles.dot} />
            <p>Entra desde tu navegador Chrome</p>
          </div>
          <div className={styles.elem}>
            <div className={styles.dot} />
            <p>Presiona el botón de menú (tres puntos en la esquina superior derecha)</p>
          </div>
          <div className={styles.elem}>
            <div className={styles.dot} />
            <p>Selecciona "Añadir a pantalla de inicio"</p>
          </div>
        </>
      );
    } else {
      return (
        <div className={styles.elem}>
          <p>Por favor, abre este sitio en tu dispositivo móvil con Safari en iOS o Chrome en Android para descargar la aplicación.</p>
        </div>
      );
    }
  };

  return (
    <div className="page">
      <div className={styles['page-content']}>
        <Header />
        <Ilustration
          className={styles.ilustration}
          ilustration="wallet"
          extraStyles={{ width: '150px', height: '150px' }}
        />
        <div className={styles.title}>
          ¡Descarga EverPay en tu celular!
        </div>
        <div className={styles.subtitle}>
          Para una mejor experiencia,
          debes descargar nuestra aplicación directamente
          desde la web a tu dispositivo
        </div>

        <div className={styles['seccion-title']}>
          Instrucciones para {os}
        </div>
        <div className={styles.explanation}>
          {renderInstructions()}
        </div>
      </div>
    </div>
  );
}

export default Download;

