import React from 'react';

import { useLocation } from 'react-router-dom';
import styles from './FlowFinish.module.css';
import Header from '../../components/Header/Header';
import Ilustration from '../../components/Ilustration/Ilustration';

function FlowFinish() {
  const location = useLocation();
  const { status, back, message } = location?.state || {};

  return (
    <div className="page">
      <div className={styles['page-content']}>
        <Header leftIcons={[back]} />
        <Ilustration
          ilustration={status}
          extraStyles={{ width: '75px', height: '75px' }}
        />
        <p>{message}</p>
      </div>
    </div>
  );
}

export default FlowFinish;
