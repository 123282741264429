import React, { useState } from 'react';
import {
  IoMdCard, IoMdCart, IoMdFilm, IoMdHeart, IoMdRestaurant, IoMdShirt,
} from 'react-icons/io';
import { IoCloseSharp } from 'react-icons/io5';
import styles from './FiltersModal.module.css';
import Button from '../../Button/Button';

function FiltersModal({
  closeModal = () => {},
  activeCategories,
  name,
  clearFilters = () => {},
  applyFilters = () => {},
}) {
  const [localActiveCategories, setLocalActiveCategories] = useState(activeCategories);
  const [localName, setLocalName] = useState(name);
  const changeCategory = (index) => {
    setLocalActiveCategories({
      ...localActiveCategories,
      [index]: !localActiveCategories[index],
    });
  };
  const borderText = '2px solid var(--orange)';

  return (
    <div
        className={styles.mask}
        onClick={closeModal}
    >
        <div
          className={styles.container}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            onClick={closeModal}
            className={styles['close-button']}
          >
            <IoCloseSharp />
          </div>
          <div className={styles['content-container']}>
            <div className={styles.title}>Filtra por categoria</div>
            <div className={styles['category-container']}>
              <div
                className={styles.category}
                style={{ border: localActiveCategories.groceries ? borderText : '' }}
                onClick={() => changeCategory('groceries')}
              >
                <IoMdCart size={20} />
                <p className={styles.text}>Super</p>
              </div>
              <div
                className={styles.category}
                style={{ border: localActiveCategories.clothing ? borderText : '' }}
                onClick={() => changeCategory('clothing')}
              >
                <IoMdShirt size={20} />
                <p className={styles.text}>Ropa</p>
              </div>
              <div
                className={styles.category}
                style={{ border: localActiveCategories.entertainment ? borderText : '' }}
                onClick={() => changeCategory('entertainment')}
              >
                <IoMdFilm size={20} />
                <p className={styles.text}>Ocio</p>
              </div>
            </div>
            <div className={styles['category-container']}>
              <div
                className={styles.category}
                style={{ border: localActiveCategories.restaurants ? borderText : '' }}
                onClick={() => changeCategory('restaurants')}
              >
                <IoMdRestaurant size={20} />
                <p className={styles.text}>Comida</p>
              </div>
              <div
                className={styles.category}
                style={{ border: localActiveCategories.life ? borderText : '' }}
                onClick={() => changeCategory('life')}
              >
                <IoMdHeart size={20} />
                <p className={styles.text}>Vida</p>
              </div>
              <div
                className={styles.category}
                style={{ border: localActiveCategories.other ? borderText : '' }}
                onClick={() => changeCategory('other')}
              >
                <IoMdCard size={20} />
                <p className={styles.text}>Otros</p>
              </div>
            </div>
            <div className={styles.divider} />
            <div className={styles.title}>Filtra por local</div>
            <input
              className={styles.input}
              type="text"
              placeholder="Nombre del local"
              value={localName}
              onChange={(e) => setLocalName(e.target.value)}
            />
            <div className={styles.divider} />
            <div className={styles.buttons}>
              <Button
                color="var(--orange)"
                text="Buscar"
                onClick={() => {
                  applyFilters(localActiveCategories, localName);
                  closeModal();
                }}
                extraStyles={{ color: 'white' }}
              />
              <Button
                color="white"
                text="Limpiar filtros"
                onClick={() => {
                  clearFilters();
                  closeModal();
                }}
                extraStyles={{ color: 'var(--orange)', border: '1px solid var(--orange)' }}
              />
            </div>
          </div>
        </div>
    </div>
  );
}

export default FiltersModal;
