import React, { useEffect, useState, useRef } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './Verify.module.css';

import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';

import Ilustration from '../../components/Ilustration/Ilustration';

import { useAuth } from '../../hooks/AuthProvider';

import { fetch, post } from '../../utils/apiConnection';

function Verify() {
  const inputRefs = useRef([]);
  const { token } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [OTP, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [verifying, setVerifying] = useState(false);
  const [resending, setResending] = useState(false);

  const email = location?.state?.email || '';
  const redirect = location?.state?.redirect || '/login';

  const verifyEmail = async (newOtp = null) => {
    try {
      const url = `/auth/verify-email?email=${email}&OTP=${newOtp || OTP}`;
      const { data: verified } = await fetch(url, token);
      if (verified) {
        navigate(redirect);
      } else {
        setVerifying(false);
        setMessage('Código incorrecto, por favor intenta nuevamente');
      }
    } catch (error) {
      setVerifying(false);
      setMessage('Código incorrecto, por favor intenta nuevamente');
    }
  };

  const resendEmail = async () => {
    try {
      const url = '/auth/new-otp';
      await post(url, { email }, token);
      setMessage('Revisa tu casilla nuevamente');
    } catch (error) {
      setResending(false);
      setMessage('Hubo un error al enviar el email, por favor intenta nuevamente');
    }
  };

  useEffect(() => {
    if (!email) {
      navigate('/login');
    }
  }, []);

  return (
    <div className={'page'}>
      <Header />
      <div className={styles['page-content']}>
        <div />
        <div className={styles.info}>
          <Ilustration
            ilustration="checkEmail"
            extraStyles={{ height: '150px', width: '150px' }}
          />
          <div className={styles.text}>
            <h2>Por favor verifica tu casilla de correo</h2>
            <p>Te hemos enviado un email a la casilla</p>
            <br />
            <p><b>{email}</b></p>
            <br />
            <p>Completa el código de 6 letras que te hemos enviado</p>
          </div>
          <div className={styles['otp-code']}>
            {Array.from({ length: 6 }).map((_, i) => (
              <input
                ref={(el) => {
                  (inputRefs.current[i] = el);
                }}
                key={i}
                type="tel"
                className={styles['otp-input']}
                value={OTP[i]}
                onChange={(e) => {
                  if (e.target.value.length >= 1) {
                    const newOtp = OTP.split('');
                    newOtp[i] = e.target.value[e.target.value.length - 1];
                    setOtp(newOtp.join(''));
                    inputRefs.current[Math.min(i + 1, 5)].focus();
                    if (i === 5) {
                      setMessage('Verificando...');
                      setVerifying(true);
                      setTimeout(() => verifyEmail(newOtp.join('')), 1000);
                    }
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    if (event.target.value === '') {
                      inputRefs.current[Math.max(i - 1, 0)].focus();
                    } else {
                      const newOtp = OTP.split('');
                      newOtp[i] = '';
                      setOtp(newOtp.join(''));
                    }
                  }
                }}
              />
            ))}
          </div>
          <Button
            color="var(--orange)"
            text="Verificar email"
            onClick={() => {
              setMessage('Verificando...');
              setVerifying(true);
              setTimeout(verifyEmail, 1000);
            }}
            extraStyles={{ color: 'white', opacity: verifying ? 0.5 : 1 }}
          />
          <Button
            color="white"
            text="Enviar código nuevo"
            onClick={() => {
              setMessage('Enviando email...');
              setResending(true);
              setTimeout(resendEmail, 1000);
            }}
            extraStyles={{
              color: 'var(--orange)',
              border: '1px solid var(--orange)',
              opacity: resending ? 0.5 : 1,
            }}
          />
          <div className={styles.message}>
            <p>{message}</p>
          </div>
        </div>
        <div className={styles.options}>
          <Link className={styles['not-registered']} to="/login">
            Ya estas verificado? Inicia sesión aquí
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Verify;
