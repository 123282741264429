import React from 'react';
import { IoHomeOutline, IoQrCodeSharp } from 'react-icons/io5';
import { MdOutlineSecurityUpdate } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import styles from './BottomMenu.module.css';

function BottomMenu() {
  const navigate = useNavigate();
  return (
    <div className={styles['bottom-menu']}>
        <div className={styles['bottom-menu-content']}>
            <div
                className={styles['bottom-menu-item']}
                onClick={() => navigate('/')}
            >
                <IoHomeOutline className={styles.icon} />
                <p>Home</p>
            </div>
            <div
                className={styles['bottom-menu-item']}
                onClick={() => navigate('/scan')}
            >
                <IoQrCodeSharp className={styles.icon} />
                <p>Paga</p>
            </div>
            <div
                className={styles['bottom-menu-item']}
                onClick={() => navigate('/security')}
            >
                <MdOutlineSecurityUpdate className={styles.icon} />
                <p>Seguridad</p>
            </div>
        </div>
    </div>
  );
}

export default BottomMenu;
