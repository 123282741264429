import React from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import styles from './BannerModal.module.css';

function BannerModal({ children, closeModal = () => {} }) {
  return (
    <div
        className={styles.mask}
        onClick={closeModal}
    >
        <div
          className={styles.container}
          onClick={(e) => e.stopPropagation()}
        >
          <>
            <div
              onClick={closeModal}
              className={styles['close-button']}
            >
              <IoCloseSharp />
            </div>
            {children}
          </>
        </div>
    </div>
  );
}

export default BannerModal;
