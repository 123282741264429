import React, { useState } from 'react';
import styles from './Passkeys.module.css';
import Authenticator from '../Authenticators/Authenticator';
import ConfirmModal from '../../Modals/ConfirmModal/ConfirmModal';
import { post } from '../../../utils/apiConnection';
import { useAuth } from '../../../hooks/AuthProvider';

function Passkeys({ authenticators, updateAuthenticators, refProp }) {
  const { token } = useAuth();
  const [showDeleteAuth, setShowDeleteAuth] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  const setToDeleteAuth = async (created) => {
    setShowDeleteAuth(true);
    setToDelete(created);
    updateAuthenticators();
  };

  const deleteAuth = async () => {
    await post('/auth/authenticators/delete/', { created: toDelete }, token);
    setShowDeleteAuth(false);
    updateAuthenticators();
  };

  return (
    <div className={styles.section}>
      <h2 className={styles.heading}>Tus claves de acceso</h2>
      <div className={styles.authenticators}>
        {authenticators.map((authenticator) => (
          <>
            <Authenticator
              key={authenticator.id}
              id={authenticator.id}
              created={authenticator.created}
              lastUsed={authenticator.lastUsed}
              timesUsed={authenticator.timesUsed}
              name={authenticator.name}
              deleteAuth={setToDeleteAuth}
            />
          </>
        ))}
      </div>
      <section className={styles.faqs} ref={refProp} >
        <p className={styles.q}>• ¿Por qué debería usar claves de acceso?</p>
        <p className={styles.a}>
          Con las claves de acceso, no necesitas recordar contraseñas complejas.
        </p>
        <p className={styles.q}>• ¿Qué son las claves de acceso?</p>
        <p className={styles.a}>
          Las claves de acceso son llaves digitales encriptadas que creas usando tu huella dactilar,
          rostro o bloqueo de pantalla.
        </p>
        <p className={styles.q}>• ¿Dónde se guardan las claves de acceso?</p>
        <p className={styles.a}>
          Las claves de acceso se guardan en tu gestor de contraseñas,
          así puedes iniciar sesión en otros dispositivos.
        </p>
      </section>
      {showDeleteAuth
        && <ConfirmModal
          onConfirm={deleteAuth}
          confirmText={'Eliminar'}
          abortText={'Cancelar'}
          onAbort={() => setShowDeleteAuth(false)}
          title={'Eliminar esta clase de acceso'}
          description={`
            Esto solo eliminará esta clave de acceso de tu cuenta,
            consulta las indicaciones especificas del dispositivo para
            eliminar tu clave de acceso de tu dispositivo.
          `}
        />
      }
    </div>
  );
}

export default Passkeys;
