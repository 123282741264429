import React from 'react';

import { ASSETS_URL } from '../../utils/constants';

const openBox = `${ASSETS_URL}/ilustrations/open-box.png`;
const checkEmail = `${ASSETS_URL}/ilustrations/check-email.png`;
const emptyCards = `${ASSETS_URL}/ilustrations/empty-cards.png`;
const wallet = `${ASSETS_URL}/ilustrations/wallet.png`;
const homeImage = `${ASSETS_URL}/ilustrations/home-image.png`;
const approved = `${ASSETS_URL}/status/approved.png`;
const unapproved = `${ASSETS_URL}/status/unapproved.png`;

const ilustrations = {
  openBox,
  checkEmail,
  emptyCards,
  wallet,
  homeImage,
  approved,
  unapproved,
};

function Ilustration({ ilustration, extraStyles = {} }) {
  return (
    <img
        src={ilustrations[ilustration]}
        alt={ilustration}
        style={extraStyles}
    />
  );
}

export default Ilustration;
