/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Payment.module.css';

import Button from '../../components/Button/Button';
import BottomMenu from '../../components/BottomMenu/BottomMenu';
import Header from '../../components/Header/Header';
import Card from '../../components/Card/Card';
import Carousel from '../../components/Carousel/Carousel';
import PaymentInfo from '../../components/PaymentInfo/PaymentInfo';
import { useFetch } from '../../hooks/useFetch';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAuth } from '../../hooks/AuthProvider';
import { post } from '../../utils/apiConnection';

const getPayload = (search) => {
  const searchParams = new URLSearchParams(search);
  return JSON.parse(atob(searchParams.get('payload')));
};

function Payment() {
  const { token, authenticateForAction } = useAuth();
  const { data: cards, loading } = useFetch('/cards', []);
  const navigate = useNavigate();
  const location = useLocation();
  const payload = getPayload(location.search);
  const merchant = payload.merchant.name;

  const [disabled, setDisabled] = useState(false);
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  const pay = async () => {
    setDisabled(true);
    const body = {
      paymentId: payload.payment.id,
      cardId: cards[activeCardIndex]._id,
    };
    const authenticated = await authenticateForAction();
    if (!authenticated) {
      setDisabled(false);
      return;
    }
    const { data, error } = await post('/payments/pay', body, token);
    if (error) {
      navigate('/flow-finish', {
        state: {
          status: 'unapproved',
          back: 'back-home',
          message: 'El pago no se ha podido procesar correctamente',
        },
      });
    } else {
      console.log(data);
      navigate('/flow-finish', {
        state: {
          status: Math.floor(data.statusCode / 100) === 2 ? 'approved' : 'unapproved',
          back: 'back-home',
          message: data.message,
        },
      });
    }
    setDisabled(false);
  };

  const addNewCard = async () => {
    navigate('/cards', {
      state: {
        text: 'Tu pago seguirá disponible durante 10 minutos en la home mientras agregas tu tarjeta',
      },
    });
  };

  return (
    <div className={'page'}>
      <Header
        title={`Pagar a ${merchant}`}
        leftIcons={['back-home']}
      />
      <div className={styles['page-content']}>
        {!loading
          && <>
          <PaymentInfo
            merchantName={merchant}
            amount={payload.payment.amount}
            styles={{ justifySelf: 'flex-start' }}
          />
          <div className={styles['lower-content']}>
            <Carousel
              activeCardIndex={activeCardIndex}
              setActiveCardIndex={setActiveCardIndex}
            >
              {cards.map((card, index) => (
                <Card
                  key={index}
                  index={index}
                  className={styles.card}
                  franchise={card.franchise}
                  lastDigits={card.lastDigits}
                  type={card.type}
                  showMore={false}
                  id={card._id}
                />
              ))}
            </Carousel>
            <Button
              color="var(--orange)"
              text={cards.length ? 'Pagar' : 'Agregar tarjeta'}
              onClick={cards.length ? pay : addNewCard}
              extraStyles={{ color: 'white' }}
              loading={disabled}
            />
          </div>
          </>
        }
        {loading && <LoadingSpinner />}

      </div>
      <BottomMenu />
    </div>
  );
}

export default Payment;
