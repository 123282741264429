import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styles from './Recover.module.css';
import Button from '../../components/Button/Button';
import { addPasskey } from '../../utils/webauthn';
import { useAuth } from '../../hooks/AuthProvider';
import Header from '../../components/Header/Header';
import Ilustration from '../../components/Ilustration/Ilustration';
import BannerModal from '../../components/Modals/BannerModal/BannerModal';
import CreatePasskeyBanner from '../../components/CreatePasskeyBanner/CreatePasskeyBanner';
import PasskeyErrorBanner from '../../components/PasskeyErrorBanner/PasskeyErrorBanner';

function Login() {
  const navigate = useNavigate();
  const { defaultEmail } = useAuth();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const name = defaultEmail.split('@')[0];
  console.log(name);
  const [disabled, setDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onGenerateNewPasskey = async (passkeyName) => {
    setDisabled(true);
    try {
      const response = await addPasskey(defaultEmail, passkeyName);
      if (response.error) throw new Error(response.error);
      if (response.message) navigate('/login');
    } catch (err) {
      console.log(err);
      if (err.message.includes('Error creating credentials NotAllowedError')) {
        setShowErrorModal(true);
      }
    } finally {
      setShowModal(false);
      setDisabled(false);
    }
  };

  return (
    <div className="page">
      <div className={styles['page-content']}>
        <Header />
        <div />
        <Ilustration
          ilustration="wallet"
          extraStyles={{ width: '150px', height: '150px' }}
        />
        <div className={styles.options}>
          <Button
            color="var(--orange)"
            text="Nueva clave de acceso"
            onClick={() => setShowModal(true)}
            extraStyles={{ color: 'white' }}
            disabled={disabled}
          />
          <Link
            className={styles['not-registered']}
            to="/login"
          >
            Ya estas registrado? Inicia sesión aquí
          </Link>
        </div>
      </div>
      {showModal
        && <BannerModal
          closeModal={() => setShowModal(false)}
        >
          <CreatePasskeyBanner
            createPasskey={(passkeyName) => onGenerateNewPasskey(passkeyName)}
            name={name}
            extraStyles={{ background: 'white' }}
          />
        </BannerModal>
      }
      {showErrorModal
        && <BannerModal
          closeModal={() => setShowErrorModal(false)}
        >
          <PasskeyErrorBanner
            retryCreatePasskey={(passkeyName) => onGenerateNewPasskey(passkeyName)}
            extraStyles={{ background: 'white' }}
          />
        </BannerModal>
      }
    </div>
  );
}

export default Login;
