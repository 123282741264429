import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import styles from './Pagination.module.css';

function Pagination({
  pages = 1, setPage = () => {}, activePage = 0,
}) {
  const getPageNumbers = () => {
    const maxDots = 7;
    const halfRange = Math.floor(maxDots / 2);
    let start = Math.max(activePage - halfRange, 0);
    const end = Math.min(start + maxDots - 1, pages - 1);
    if (end - start < maxDots - 1) {
      start = Math.max(end - maxDots + 1, 0);
    }
    const pageNumbers = [];
    for (let i = start; i <= end; i += 1) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <div className={styles['carousel-dots']}>
      <div
        className={styles['carousel-arrow']}
        onClick={() => {
          if (activePage === 0) setPage(pages - 1);
          else setPage((activePage - 1) % pages);
        }}
      >
        <IoIosArrowBack />
      </div>
      {getPageNumbers().map((index) => (
        <div
          key={index}
          className={`
            ${styles['carousel-dot']}
            ${index === activePage ? styles['carousel-dot-active'] : ''}
          `}
          onClick={() => setPage(index)}
        />
      ))}
      <div
        className={styles['carousel-arrow']}
        onClick={() => setPage((activePage + 1) % pages)}
      >
        <IoIosArrowForward />
      </div>
    </div>
  );
}

export default Pagination;
