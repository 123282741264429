import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { ASSETS_URL } from '../../utils/constants';
import styles from './Card.module.css';

const franchises = {
  master: {
    img: `${ASSETS_URL}/cards/master.png`,
    height: '42px',
    width: '42px',
  },
  visa: {
    img: `${ASSETS_URL}/cards/visa.png`,
    height: '14px',
    width: '42px',
  },
};

function Card(props) {
  const {
    franchise, lastDigits, index, type, showMore, extraStyles = {},
  } = props;
  const navigate = useNavigate();

  return (
    <div
      className={styles.card}
      onClick={() => showMore && navigate('/delete-card', { state: props })}
    >
        <div
          className={`
            ${styles[`image-${(index % 4) + 1}`]}
            ${styles['image-general']}
          `}
          style={extraStyles}
        >
          <div className={styles.upper}>
            <p className={styles['card-type']}>{type}</p>
            <img
              src={franchises[franchise].img}
              alt={franchise}
              style={{
                height: franchises[franchise].height,
                width: franchises[franchise].width,
              }}
            />
          </div>
          <div className={styles.lower}>
            <div className={styles['last-digits']}>
              <span className={styles.digits}>**** **** ****</span>
              <span className={styles.numbers}>{lastDigits}</span>
            </div>
            {showMore && <MdKeyboardArrowRight className={styles.icon} />}
          </div>
        </div>

    </div>
  );
}

export function CardHome() {
  const navigate = useNavigate();
  return (
    <div
      className={styles.card}
      onClick={() => navigate('/cards')}
    >
        <div
          className={`
            ${styles['image-1']}
            ${styles['image-general']}
          `}
        >
          <div className={styles.lower}>
            <div className={styles['last-digits']}>
            <p className={styles['card-type']}>Ver tus tarjetas</p>
            </div>
            <MdKeyboardArrowRight className={styles.icon} />
          </div>
        </div>
    </div>
  );
}

export default Card;
