import React from 'react';
import styles from './Input.module.css';

function Input({
  type, placeholder, value, changeValue, label, errorMsg,
}) {
  return (
        <div className={styles['input-container']}>
            <p className={styles['input-label']}>{label}</p>
            <input
                className={styles.input}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={(e) => changeValue(e.target.value)}
                style={{ border: errorMsg ? '1px solid red' : '' }}
            />
            {errorMsg && <p className={styles['input-error']}>{errorMsg}</p>}

        </div>
  );
}

export default Input;
