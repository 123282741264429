import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Signup.module.css';
import { useAuth } from '../../hooks/AuthProvider';
import { register } from '../../utils/webauthn';
import { fetch } from '../../utils/apiConnection';

import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import Ilustration from '../../components/Ilustration/Ilustration';
import BannerModal from '../../components/Modals/BannerModal/BannerModal';
import CreatePasskeyBanner from '../../components/CreatePasskeyBanner/CreatePasskeyBanner';
import PasskeyErrorBanner from '../../components/PasskeyErrorBanner/PasskeyErrorBanner';
import Input from '../../components/Input/Input';

function Signup() {
  const { register: sendToRegisterPage } = useAuth();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [lastname, setLastname] = useState('');
  const [lastnameError, setLastnameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const clearErrors = () => {
    setNameError('');
    setEmailError('');
    setPhoneError('');
  };

  const validateRequestAndRegister = async () => {
    setDisabled(true);
    clearErrors();
    if (!name) {
      setNameError('Name is required');
      setDisabled(false);
      return;
    }
    if (!lastname) {
      setLastnameError('Lastname is required');
      setDisabled(false);
      return;
    }
    if (!email) {
      setEmailError('Email is required');
      setDisabled(false);
      return;
    }
    if (!phone) {
      setPhoneError('Phone is required');
      setDisabled(false);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email format');
      setDisabled(false);
      return;
    }
    const { data: emailAvailable } = await fetch(`/auth/new-email-availability?email=${email}`);
    if (!emailAvailable) {
      setEmailError('Email is already in use');
      setDisabled(false);
      return;
    }
    setShowModal(true);
    setDisabled(false);
  };

  const onAcceptRegister = async (passkeyName) => {
    try {
      const response = await register({
        name,
        lastname,
        email,
        phone,
        passkeyName,
      });
      if (response.error) throw new Error(response.error);
      if (response.message) sendToRegisterPage(email);
    } catch (err) {
      console.log(err);
      if (err.message.includes('Error creating credentials NotAllowedError')) {
        setShowErrorModal(true);
      }
    } finally {
      setShowModal(false);
    }
  };

  return (
    <div className={'page'}>
      <Header />
      <div className={styles['page-content']}>
        <div />
        <Ilustration
          ilustration="openBox"
          extraStyles={{ width: '150px', height: '150px' }}
        />
        <div className={styles.options}>
          <Input
            type="text"
            placeholder="Nombre"
            value={name}
            changeValue={(value) => setName(value)}
            label="Nombre"
            errorMsg={nameError}
          />
          <Input
            type="text"
            placeholder="Apellido"
            value={lastname}
            changeValue={(value) => setLastname(value)}
            label="Apellido"
            errorMsg={lastnameError}
          />
          <Input
            type="email"
            placeholder="Email"
            value={email}
            changeValue={(value) => setEmail(value.toLowerCase())}
            label="Email"
            errorMsg={emailError}
          />
          <Input
            type="tel"
            placeholder="Teléfono"
            value={phone}
            changeValue={(value) => setPhone(value)}
            label="Teléfono"
            errorMsg={phoneError}
          />
          <Button
            color="var(--orange)"
            text="Registrate"
            onClick={validateRequestAndRegister}
            extraStyles={{ color: 'white', marginTop: '5px' }}
            disabled={disabled}
          />
          <Link className={styles['not-registered']} to="/login">
            Ya estas registrado? Inicia sesión aquí
          </Link>
        </div>
      </div>
      {showModal
        && <BannerModal
          closeModal={() => setShowModal(false)}
        >
          <CreatePasskeyBanner
            createPasskey={(passkeyName) => onAcceptRegister(passkeyName)}
            name={name}
            extraStyles={{ background: 'white' }}
          />
        </BannerModal>
      }
      {showErrorModal
        && <BannerModal
          closeModal={() => setShowErrorModal(false)}
        >
          <PasskeyErrorBanner
            retryCreatePasskey={(passkeyName) => onAcceptRegister(passkeyName)}
            extraStyles={{ background: 'white' }}
          />
        </BannerModal>
      }
    </div>
  );
}

export default Signup;
