/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import styles from './Home.module.css';

import BottomMenu from '../../components/BottomMenu/BottomMenu';

import Header from '../../components/Header/Header';
import { fetch } from '../../utils/apiConnection';
import { useAuth } from '../../hooks/AuthProvider';
import { PaymentInfoHome, PaymentInfoResume } from '../../components/PaymentInfo/PaymentInfo';
import { CardHome } from '../../components/Card/Card';
import LoadingSpinner from '../../components/LoadingSpinner/index';
import Hello from '../../components/Hello/Hello';

function Home() {
  const { token } = useAuth();
  const [pendingPayment, setPendingPayment] = useState(null);
  const [recentPayments, setRecentPayments] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchPendingPayments = async () => {
    const { data } = await fetch('/payments/pending', token);
    if (data) {
      const payload = JSON.parse(atob(data.paymentUrl.split('payload=')[1]));
      setPendingPayment({ ...payload, createdAt: data.createdAt, redirect: data.paymentUrl });
    }
  };

  const fetchPayments = async () => {
    const url = `/payments/completed?limit=${3}&page=0`;
    const { data, error } = await fetch(url, token);
    if (data) {
      setRecentPayments(data.payments);
    } else if (error) {
      console.log(error);
    }
  };

  const fetchUserProfile = async () => {
    const url = '/users/profile';
    const { data, error } = await fetch(url, token);
    if (data) {
      setUser(data);
    } else if (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    await Promise.all([
      fetchPendingPayments(),
      fetchPayments(),
      fetchUserProfile(),
    ]);
    setLoading(false);
  };

  const getName = () => `${user?.name || ''} ${user?.lastname || ''}`;

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={'page'}>
      <Header title="EverPay" rightIcons={['logout']} />
      <div className={styles['page-content']}>
        {loading && <LoadingSpinner />}
        {!loading
          && <Hello
            name={getName()}
          />
        }
        {!loading && pendingPayment
          && <PaymentInfoHome
            merchantName={pendingPayment.merchant.name}
            amount={pendingPayment.payment.amount}
            createdAt={pendingPayment.createdAt}
            redirect={pendingPayment.redirect}
          />
        }
        {!loading && <PaymentInfoResume payments={recentPayments} />}
        {!loading && <CardHome />}
      </div>
      <BottomMenu />
    </div>
  );
}

export default Home;
