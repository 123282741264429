import React from 'react';
import styles from './PasskeyLoginErrorBanner.module.css';
import { ASSETS_URL } from '../../utils/constants';
import Button from '../Button/Button';

const passkeyWentWrongImg = `${ASSETS_URL}/webauthn/passkey-went-wrong.png`;

function PasskeyLoginErrorBanner({
  retryAction, retryMessage, errorDescription, extraStyles = {},
}) {
  return (
    <section className={styles['create-passkey']} style={extraStyles} >
        <img
            src={passkeyWentWrongImg}
            alt="passkey-went-wrong"
            className={styles['create-passkey-img']}
        />
        <div className={styles['passkey-not-created']}>
            <h3>Ups! Algo salió mal</h3>
            <p className={styles.a}>
                {errorDescription}
            </p>
            {retryAction ? <Button
              color="var(--orange)"
              text={retryMessage}
              onClick={retryAction}
              extraStyles={{ color: 'white' }}
            /> : null}
        </div>
    </section>
  );
}

export default PasskeyLoginErrorBanner;
