import React from 'react';
import styles from './Button.module.css';

function Button({
  onClick, color, text, extraStyles = {}, disabled = false, loading = false,
}) {
  return (
    <div
      className={styles.wrapper}
      style={{
        ...extraStyles,
        backgroundColor: disabled ? 'grey' : color,
      }}
    >
      <div
        className={styles.button}
        onClick={disabled || loading ? () => {} : onClick}
      >
        {text}
      </div>
      {loading && <div className={styles.progress} />}
    </div>
  );
}

export default Button;
