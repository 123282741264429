import { useState, useEffect } from 'react';
import { fetch } from '../utils/apiConnection';
import { useAuth } from './AuthProvider';

export const useFetch = (url, def = null) => {
  const { logOut, token } = useAuth();
  const cacheKey = url + JSON.stringify(token);
  const cachedData = JSON.parse(localStorage.getItem(cacheKey));
  const [data, setData] = useState(def);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    const response = await fetch(url, token);
    if (response.data) {
      setData(response.data);
      localStorage.setItem(cacheKey, JSON.stringify(response.data));
    } else if (response.error) {
      setError(response.error);
      if (response.error.response?.status === 401) {
        logOut();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return {
    data, loading, error, fetchData, cachedData,
  };
};
