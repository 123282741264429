export function formatDate(date) {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  if (year < 1971) {
    return '-';
  }
  return `${day}/${month}/${year} ${d.toISOString().split('T')[1].split('.')[0]}`;
}
