import React from 'react';
import { useNavigate } from 'react-router-dom';

import { IoIosArrowBack, IoIosSearch } from 'react-icons/io';
import { CiLogout } from 'react-icons/ci';
import styles from './Header.module.css';
import { useAuth } from '../../hooks/AuthProvider';

function Header({
  title = 'EverPay', leftIcons = [], rightIcons = [], auxF = () => {},
}) {
  const navigate = useNavigate();
  const { logOut } = useAuth();

  const icons = {
    'delete-cards-back': {
      children: <IoIosArrowBack />,
      onClick: () => navigate(-1),
    },
    'cards-back': {
      children: <IoIosArrowBack />,
      onClick: () => navigate('/cards'),
    },
    'back-home': {
      children: <IoIosArrowBack />,
      onClick: () => navigate('/'),
    },
    logout: {
      children: <CiLogout />,
      onClick: logOut,
    },
    'search-filters': {
      children: <IoIosSearch />,
      onClick: auxF,
    },
  };

  return (
    <div className={styles.header}>
        <div className={styles['header-content']}>
          <div className={styles['left-icons']}>
            {leftIcons.map((icon, index) => (
              <div
                key={index}
                className={styles.icon}
                onClick={icons[icon].onClick}
              >
                {icons[icon].children}
              </div>
            ))}
          </div>
          <h1 className={styles['header-title']}>{title}</h1>
          <div className={styles['right-icons']}>
            {rightIcons.map((icon, index) => (
              <div
                key={index}
                className={styles.icon}
                onClick={icons[icon].onClick}
              >
                {icons[icon].children}
              </div>
            ))}
          </div>
        </div>
    </div>
  );
}

export default Header;
