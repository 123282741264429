import React, { useState, useRef } from 'react';
import styles from './Security.module.css';
import Passkeys from '../../components/Security/Passkeys/Passkeys';

import { useAuth } from '../../hooks/AuthProvider';
import { addPasskey } from '../../utils/webauthn';
import { useFetch } from '../../hooks/useFetch';
import BottomMenu from '../../components/BottomMenu/BottomMenu';
import Header from '../../components/Header/Header';
import CreatePasskeyBanner from '../../components/CreatePasskeyBanner/CreatePasskeyBanner';
import PasskeyCreatedBanner from '../../components/PasskeyCreatedBanner/PasskeyCreatedBanner';

function Security() {
  const { defaultEmail } = useAuth();
  const [showPasskeyCreated, setShowPasskeyCreated] = useState(false);
  const myRef = useRef(null);
  const {
    data: authenticators,
    fetchData: getAuthenticators,
  } = useFetch('/auth/authenticators', []);

  const addPasskeyAndUpdate = async (passkeyName) => {
    const res = await addPasskey(defaultEmail, passkeyName);
    if (res.error) {
      return;
    }
    getAuthenticators();
    setShowPasskeyCreated(true);
  };

  const scrollToPasskey = () => {
    myRef.current.scrollIntoView({ behavior: 'smooth' });
    setShowPasskeyCreated(!showPasskeyCreated);
  };

  return (
    <div className={'page'}>
      <Header
        title="Seguridad"
        leftIcons={['back-home']}
      />
      <div className={styles['page-content']}>
        {!showPasskeyCreated
          ? <CreatePasskeyBanner
            createPasskey={(passkeyName) => addPasskeyAndUpdate(passkeyName)}
          />
          : <PasskeyCreatedBanner scrollToPasskey={scrollToPasskey} />
        }
        <section className={styles['sign-in-options']}>
          <h3 className={styles['sign-in-options-title']}>Opciones de Sign In</h3>
          <ol>
            <li>Iniciar sesión con una clave de acceso.</li>
            <li>Iniciar sesión con una contraseña tradicional.</li>
          </ol>
        </section>
        <Passkeys
          refProp={myRef}
          authenticators={authenticators}
          updateAuthenticators={getAuthenticators}
        />
      </div>
      <BottomMenu />
    </div>
  );
}

export default Security;
