import React, { useEffect, useState } from 'react';
import {
  IoIosArrowForward,
  IoMdCart,
  IoMdHeart,
  IoMdFilm,
  IoMdCard,
  IoMdRestaurant,
  IoMdShirt,
} from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import styles from './PaymentInfo.module.css';

const formatDate = (d) => {
  const auxDate = new Date(d);
  return `${auxDate.getDate()}/${auxDate.getMonth() + 1}/${auxDate.getFullYear()}`;
};

const icons = {
  groceries: <IoMdCart size={20}/>,
  entertainment: <IoMdFilm size={20} />,
  life: <IoMdHeart size={20} />,
  restaurants: <IoMdRestaurant size={20} />,
  clothing: <IoMdShirt size={20} />,
  other: <IoMdCard size={20} />,
};

function PaymentInfo({ merchantName, amount }) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <IoMdCart size={40}/>
        <p className={styles.text}>Compra en {merchantName}</p>
      </div>
      <div className={styles.total}>
        <p>Total</p>
        <p>$ {amount}</p>
      </div>
    </div>
  );
}

export function PaymentInfoResume({ payments }) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate('/activity')}
      className={styles['resume-container']}
    >
      <div className={styles['resume-title']}>
        <h4>Tu última actividad</h4>
        <div className={styles['right-side']}>
          <p className={styles.link}>Ver todo</p>
          <IoIosArrowForward size={20} />
        </div>
      </div>
      <div className={styles['resume-lines']}>
        {payments.map((p, i) => (
          <div key={i}>
            <div className={styles['resume-line']}>
              <div className={styles['resume-left']}>
                {icons[p.category] || icons.other}
                <p>{p.merchantName}</p>
              </div>
              <div className={styles['resume-right']}>
                <p>$ {p.amount}</p>
                <p className={styles.date}>{formatDate(p.date)}</p>
              </div>
            </div>
          </div>
        ))}
        {!payments.length
          && <div className={styles['resume-line']}>
              <div className={styles['resume-left']}>
                <p>Haz tu primer pago y esto se empezará a mover!</p>
              </div>
          </div>
        }
      </div>
    </div>
  );
}

export function PaymentInfoHome({
  merchantName, amount, createdAt, redirect,
}) {
  const [timeLeft, setTimeLeft] = useState('');
  let intervalId = '';
  const navigate = useNavigate();

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date();
      const expiration = new Date(createdAt);
      expiration.setMinutes(expiration.getMinutes() + 10);
      const diff = expiration - now;
      if (diff <= 0) {
        setTimeLeft('0:00');
        clearInterval(intervalId);
        return;
      }
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      const seconds = Math.floor((diff / 1000) % 60);
      setTimeLeft(`${minutes}:${seconds.toString().padStart(2, '0')}`);
    };

    intervalId = setInterval(updateTimer, 1000);
    updateTimer();

    return () => clearInterval(intervalId);
  }, [createdAt]);

  return (
    <div
      onClick={() => timeLeft !== '0:00' && navigate(redirect)}
      className={styles.container}>
      <div className={styles['home-header']}>
        <div className={styles.side}>
          <IoMdCart size={20} />
          <p className={styles.text}>{merchantName}</p>
        </div>
        <div className={styles['right-side']}>
          <p className={styles.text}>Ir a pago</p>
          <IoIosArrowForward size={20} />
        </div>
      </div>
      <div className={styles.total}>
        <p>{timeLeft === '0:00' ? 'Expirado' : `Expira en ${timeLeft}`}</p>
        <p>$ {amount}</p>
      </div>
    </div>
  );
}

export default PaymentInfo;
