/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import styles from './Activity.module.css';

import LoadingSpinner from '../../components/LoadingSpinner';
import Ilustration from '../../components/Ilustration/Ilustration';
import BottomMenu from '../../components/BottomMenu/BottomMenu';
import Header from '../../components/Header/Header';
import Payment from '../../components/Payment/Payment';
import Pagination from '../../components/Pagination/Pagination';
import FiltersModal from '../../components/Modals/FiltersModal/FilterModal';
import { fetch } from '../../utils/apiConnection';
import { useAuth } from '../../hooks/AuthProvider';
import Button from '../../components/Button/Button';

const initialFilters = {
  groceries: false,
  entertainment: false,
  life: false,
  clothing: false,
  restaurants: false,
  other: false,
};

function Activity() {
  const [showFilters, setShowFilters] = useState(false);
  const [activeCategories, setActiveCategories] = useState(initialFilters);
  const [name, setName] = useState('');
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useAuth();
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const fetchPayments = async (newCats = '[]', newName = '', newPage = 0) => {
    setLoading(true);
    let url = `/payments/completed?limit=${pageSize}&page=${newPage}`;
    url += newName ? `&merchant=${newName}` : '';
    url += newCats ? `&categories=${newCats}` : '';
    const { data, error } = await fetch(url, token);
    if (data) {
      setPayments(data.payments);
      setTotalPages(data.totalPages);
    } else if (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const clearFilters = () => {
    setActiveCategories(initialFilters);
    setName('');
    setPage(0);
    fetchPayments();
  };

  const isFilterActive = name !== '' || Object.keys(activeCategories).some((k) => activeCategories[k]);

  const applyFilters = ({
    newCats = activeCategories,
    newName = name,
    newPage = page,
  }) => {
    setActiveCategories(newCats);
    const newCatsSanitized = JSON.stringify(Object.keys(newCats).filter((k) => newCats[k]));
    setName(newName);
    setPage(newPage);
    fetchPayments(newCatsSanitized, newName, newPage);
  };

  const fetchNewPage = (n) => {
    applyFilters({ newPage: n });
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  return (
    <div className={'page'}>
      <Header
        title={'Tu actividad'}
        rightIcons={['search-filters']}
        leftIcons={['back-home']}
        auxF={() => setShowFilters(true)}
      />
      <div className={styles['page-content']}>
        {!loading && !payments.length && <>
          <div />
          <div className={styles['empty-state']}>
            <Ilustration
              ilustration="emptyCards"
              extraStyles={{ width: '200px', height: '200px' }}
            />
            <p className={styles['empty-message']}>
              {isFilterActive ? 'No hay pagos con los filtros seleccionados' : 'Aun no has realizado ningun pago.'}
            </p>
            {isFilterActive
              && <Button
                color="var(--orange)"
                text="Limpiar filtros"
                onClick={clearFilters}
                extraStyles={{ width: '200px', height: '40px', color: 'white' }}
              />
            }
          </div>
        </>}
        {loading && <LoadingSpinner />}
        <div className={styles['payments-container']}>
          {!loading && payments.map((p, index) => (
            <Payment
              merchantName={p.merchantName}
              amount={p.amount}
              category={p.category}
              date={p.date}
              key={index}
            />
          ))}
        </div>
        <div className={styles['bottom-bar']}>
          {totalPages > 1
          && <Pagination
            pages={totalPages}
            activePage={page}
            setPage={(n) => fetchNewPage(n)}
          />}
        </div>
      </div>
      <BottomMenu />
      {showFilters
        && <FiltersModal
            closeModal={() => setShowFilters(false)}
            activeCategories={activeCategories}
            name={name}
            clearFilters={clearFilters}
            applyFilters={(newCats, newName) => applyFilters({
              newCats,
              newName,
              newPage: 0,
            })}
          />
      }
    </div>
  );
}

export default Activity;
