import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './Identify.module.css';
import Button from '../../components/Button/Button';
import { authenticate } from '../../utils/webauthn';
import { useAuth } from '../../hooks/AuthProvider';
import { requestToken } from '../../utils/pushNotifications';
import Header from '../../components/Header/Header';
import BannerModal from '../../components/Modals/BannerModal/BannerModal';
import PasskeyLoginErrorBanner from '../../components/PasskeyLoginErrorBanner/PasskeyLoginErrorBanner';
import PaymentInfo from '../../components/PaymentInfo/PaymentInfo';

const getPayload = (search) => {
  const searchParams = new URLSearchParams(search);
  return JSON.parse(atob(searchParams.get('payload')));
};

function Identify() {
  const {
    logIn, recover,
  } = useAuth();
  const location = useLocation();
  const payload = getPayload(location.search);
  const { email } = payload.payer;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const validateAndLogin = async () => {
    setDisabled(true);
    if (email) {
      const deviceToken = await requestToken();
      const response = await authenticate(email, deviceToken);
      if (response.error) {
        setShowErrorModal(true);
      }
      if (response.message) {
        const redirectUrl = `/payment${location.search}`;
        logIn(response, email, deviceToken, redirectUrl);
      }
    } else {
      setShowErrorModal(true);
    }
    setDisabled(false);
  };

  return (
    <div className="page">
      <div className={styles['page-content']}>
        <Header />
        <div />
        <PaymentInfo
          merchantName={payload.merchant.name}
          amount={payload.payment.amount}
        />
        <div className={styles.options}>
          <input
            className={styles.input}
            type="text"
            placeholder="Email"
            disabled={true}
            value={email}
          />
          <Button
            color="var(--orange)"
            text="Proceder a pago"
            onClick={validateAndLogin}
            extraStyles={{ color: 'white', marginBottom: '10px' }}
            disabled={disabled}
          />
        </div>
      </div>
      {showErrorModal
        && <BannerModal
          closeModal={() => setShowErrorModal(false)}
        >
          <PasskeyLoginErrorBanner
            retryAction={() => recover(email)}
            retryMessage={'Recuperar cuenta'}
            errorDescription={'No tienes acceso a ninguna de tus claves? Recupera tu cuenta aquí'}
            extraStyles={{ background: 'white' }}
          />
        </BannerModal>
      }
    </div>
  );
}

export default Identify;
