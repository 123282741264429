/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import styles from './Cards.module.css';

import LoadingSpinner from '../../components/LoadingSpinner';
import Ilustration from '../../components/Ilustration/Ilustration';
import Button from '../../components/Button/Button';
import { useAuth } from '../../hooks/AuthProvider';
import { fetch } from '../../utils/apiConnection';
import BottomMenu from '../../components/BottomMenu/BottomMenu';
import Header from '../../components/Header/Header';
import Card from '../../components/Card/Card';

function Cards() {
  const { token } = useAuth();
  const { data: cards, loading } = useFetch('/cards', []);
  const location = useLocation();
  const text = location?.state?.text || 'Carga los datos de tus tarjetas y ahorra tiempo cuando quieras pagar.';

  const addNewCard = async () => {
    const { data } = await fetch('/payments/register-card/', token);
    console.log(data.processUrl);
    window.location.href = data.processUrl;
  };

  return (
    <div className={'page'}>
      <Header
        title={'Tus tarjetas'}
        leftIcons={['back-home']}
      />
      <div className={styles['page-content']}>
        {!loading && !cards.length && <>
          <div />
          <div className={styles['empty-state']}>
            <Ilustration
              ilustration="emptyCards"
              extraStyles={{ width: '200px', height: '200px' }}
            />
            <p className={styles['empty-message']}>{text}</p>
          </div>
        </>}
        {loading && <LoadingSpinner />}
        <div className={styles['cards-container']}>
          {(cards || []).map((card, index) => (
            <Card
              key={index}
              lastDigits={card.lastDigits}
              franchise={card.franchise}
              type={card.type}
              index={index}
              id={card._id}
              showMore={true}
            />
          ))}
        </div>
        <Button
          color="var(--orange)"
          text="Agrega una nueva tarjeta"
          onClick={addNewCard}
          extraStyles={{ color: 'white' }}
        />
      </div>
      <BottomMenu />
    </div>
  );
}

export default Cards;
