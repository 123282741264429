import React from 'react';
import {
  IoMdHeart,
  IoMdFilm,
  IoMdCard,
  IoMdCart,
  IoMdRestaurant,
  IoMdShirt,
} from 'react-icons/io';
import styles from './Payment.module.css';

const icons = {
  groceries: <IoMdCart size={20}/>,
  entertainment: <IoMdFilm size={20} />,
  life: <IoMdHeart size={20} />,
  restaurants: <IoMdRestaurant size={20} />,
  clothing: <IoMdShirt size={20} />,
  other: <IoMdCard size={20} />,
};

function PaymentInfo({
  merchantName, amount, date, category = 'other',
}) {
  const formatDate = (d) => {
    const auxDate = new Date(d);
    return `${auxDate.getDate()}/${auxDate.getMonth() + 1}/${auxDate.getFullYear()}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {icons[category] || icons.other}
        <p className={styles.text}>{merchantName}</p>
      </div>
      <div className={styles.total}>
        <p>$ {amount}</p>
        <p>{formatDate(date)}</p>
      </div>
    </div>
  );
}

export default PaymentInfo;
